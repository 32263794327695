<template>
  <span :class="'search-filter-label ' + classes"
    >{{ title }} <img @click="onClickCross" src="@/assets/images/svg/close.svg" alt=""
  /></span>
</template>

<script>
export default {
  name: "SearchFilterLabel",
  props: {
    title: {
      type: String,
      required: true
    },
    classes: String
  },
  methods: {
    onClickCross(e) {
      this.$emit("cross-click");
    }
  }
};
</script>
<style>

</style>